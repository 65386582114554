const Colors = {
  LIGHTGRAY: '#00000066',
  DARKGRAY: '#000000B3',
  NAVERGREEN: '#01C73C',
  LIGHTVIOLET: '#C0C5EB',
  LIGHTBEIGE: '#FFFFF0',
  LIGHTSKY: '#D3EEFF',
};

export default Colors;
